import React from 'react';
import { ResponsiveContext, Box, Text, Anchor } from 'grommet';

import { Tag } from '../../../components';

const Card = ({ name, number, votes, party, partyColor, isElected, onClick }) => {
  return (
    <ResponsiveContext.Consumer>
      {size => (
        <Box
          round='xsmall'
          width={
            size === 'xsmall' || size === 'small' ? '100vw' : (
              size === 'medium' ? '28vw' : '23vw'
            )
          }
          pad={{
            vertical: size === 'xsmall' || size === 'small' ? 'medium' : 'small',
            horizontal: size === 'xsmall' || size === 'small' ? 'medium' : 'small',
          }}
          border={{ color: 'light-5' }}
          margin={{ bottom: 'small' }}
          gap='medium'
        >
          <Box
            direction='row'
            justify='between'
          >
            <Box
              direction='row'
              gap='small'
            >
              <Anchor
                title='Abrir detalhes'
                label={
                  <Text truncate size='small'>
                    {name}
                  </Text>
                }
                onClick={onClick}
              />

              <Text size='small' color='dark-1' weight='bolder'>{number}</Text>
            </Box>

            <Box>
              <Tag
                text={party}
                color={partyColor}
              />
            </Box>
          </Box>

          <Box
            direction='row'
            justify='between'
            align='center'
            gap='small'
          >
            <Tag
              text={isElected ? 'eleito' : 'não eleito'}
              color={isElected ? 'status-ok' : 'light-6'}
            />

            <Text size='xsmall' color='dark-1'>{votes} votos</Text>
          </Box>
        </Box>
      )}
    </ResponsiveContext.Consumer>
  )
}

export default Card;
