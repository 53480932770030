import { DashboardSystem } from '../../modules/home/pages';
import { DashboardVoter } from '../../modules/voter/pages';
import { DashboardService } from '../../modules/service/pages';
import { DashboardCandidate } from '../../modules/candidate/pages';
import { DashboardCity } from '../../modules/city/pages';

import urlRoutes from '../../config/routes-url';

const RoutesMap = ([
  {
    path: urlRoutes.home,
    element: <DashboardSystem />,
  },
  {
    path: urlRoutes.voters,
    element: <DashboardVoter />,
  },
  {
    path: urlRoutes.services,
    element: <DashboardService />,
  },
  {
    path: urlRoutes.candidates,
    element: <DashboardCandidate />,
  },
  {
    path: urlRoutes.cities,
    element: <DashboardCity />,
  }
])

export { RoutesMap };
