import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { ResponsiveContext, Box, Text, Button } from 'grommet';
import { Step } from './step';

import { openLayer } from '../../../actions/layers';

const Explain = (props) => {
  const { openLayer } = props;

  return (
    <ResponsiveContext.Consumer>
      {size => (
        <Box
          direction='row'
          justify='center'
          margin={{ horizontal: 'medium', top: '100px' }}
        >
          <Box
            pad='small'
            width='xlarge'
          >
            <Box
              direction='row'
              gap={ size === 'small' ? 'small' : 'large' }
              align='start'
              justify='between'
            >
              {/* primeira coluna */}
              <Box
                gap={ size === 'small' ? 'small' : 'medium' }
                width={
                  size === 'small' || size === 'xsmall' ? 'xlarge' : 'large'
                }
                fill
              >
                {/* O que é */}
                <Box
                  gap={ size === 'small' ? 'medium' : 'small' }
                >
                  <Text color='brand' size='large' weight='bold'>
                    Detalhamento por município, zona eleitoral e local de votação.
                  </Text>

                  <Text color='dark-2' size='small'>
                    Ideal para candidatos, consultores e estrategistas que querem entender a fundo o desempenho nas urnas.
                  </Text>
                </Box>


                {/* Como funciona */}
                <Box
                  gap={ size === 'small' ? 'medium' : 'small' }
                >
                  <Text color='dark-1' size='medium'>Como funciona?</Text>

                  <Step
                    index={1}
                    description='Acesse os filtros e selecione uma cidade.'
                  />

                  <Step
                    index={2}
                    description='Clique em buscar e começe analisar!'
                  />

                  <Button
                    label='Começar'
                    color='dark-3'
                    onClick={openLayer}
                  />
                </Box>

                {/* O que fazer */}
                <Box
                  gap={ size === 'small' ? 'medium' : 'small' }
                >
                  <Text color='dark-1' size='medium'>O que é possível?</Text>

                  <Step icon description='Filtre os candidatos por cidade, cargo e partido.' />
                  <Step icon description='Analise o desempenho por candidato.' />
                  <Step icon description='Detalhamento dos votos por bairro e local de votação.' />
                </Box>

                {/* Fonte */}
                <Box
                  gap={ size === 'small' ? 'medium' : 'small' }
                  margin={{ horizontal: 'medium' }}
                >
                  <Text size='xsmall' color='dark-1'>As informações dessa funcionalidade são do TSE.</Text>
                </Box>
              </Box>

              {/* Separador coluna */}
              {(size !== 'xsmall' && size !== 'small') ? (
                <Box
                  width={ size === 'large' || size === 'xlarge' ? 'xsmall' : 'xxsmall'}
                />) : null}

              {/* segunda coluna */}
              {(size !== 'xsmall' && size !== 'small') ? (
                <Box
                  width={ size === 'small' || size === 'medium' ? 'xsmall' : 'large' }
                  align='end'
                />) : null}
            </Box>
          </Box>
        </Box>
      )}
    </ResponsiveContext.Consumer>
  )
};

const mapStateToProps = null;

const mapDispatchToProps = dispatch =>
  bindActionCreators({
    openLayer,
  }, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(Explain);

