import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { ResponsiveContext, Box, Button, Text, Tip } from 'grommet';
import { Search, Download } from 'grommet-icons';

import Card from './card';
import Explain from './explain';

import { EmptySearches, ErrorSearches, LoadSearches } from '../../../components';

import { toggleDetailsLayer } from '../../../actions/details';
import { openLayer } from '../../../actions/layers';

const SearchResult = (props) => {
  const {
    // functions
    openLayer,
    toggleDetailsLayer,

    //data
    dataRows,
    loading,
    error,
    filtered,
  } = props;

  if(loading && !error) {
    return <LoadSearches />;
  }

  if(error) {
    return <ErrorSearches
      button={{
        label: 'Abrir filtros',
        action: openLayer
      }}
    />;
  }

  if(!filtered) {
    return <Explain />;
  }

  if(dataRows.length <= 0) {
    return <EmptySearches
      buttonFilters={{
        label: 'Abrir filtros',
        action: openLayer,
      }}
    />;
  }


  return (
    <ResponsiveContext.Consumer>
      {size => (
        <Box
          direction='row'
          justify='center'
          margin={{ horizontal: 'medium', top: '80px' }}
        >
          <Box
            pad='small'
            width='xlarge'
          >
            <Box
              align='center'
              direction='row'
              justify='between'
              margin={{ bottom: 'small' }}
            >
              <Box
                direction={size === 'small' ? 'column' : 'row'}
                align={size === 'small' ? 'baseline' : 'center'}
                gap={size === 'small' ? null : 'small'}
              >
                <Text color='dark-1' size='xlarge'>{dataRows.length}</Text>

                <Text
                  size={size === 'small' ? 'xsmall' : 'medium'}
                  color='dark-6'
                >
                  registro(s)
                </Text>
              </Box>

              <Box
                direction='row'
                align='center'
                gap={size === 'small' ? 'large' : 'medium'}
              >
                <Tip
                  content={
                    <Box
                      background='light-4'
                      pad='xxsmall'
                    >
                      <Text size='xsmall' color='dark-2'>Estamos construindo essa funcionalidade, Aguarde!</Text>
                    </Box>
                  }
                  plain
                >
                  <Button
                    plain
                    icon={<Download />}
                    size='small'
                  />
                </Tip>

                <Button
                  plain
                  title='Filtrar'
                  icon={<Search />}
                  onClick={() => { openLayer() }}
                />
              </Box>
            </Box>

            {/* Por Prefeito */}
            {dataRows?.length > 0 && (
              <Box
                gap='small'
                margin={{ vertical: 'medium' }}
              >
                <Box
                  direction={
                    size === 'xsmall' || size === 'small' ? 'column' : 'row'
                  }
                  gap={ size === 'xsmall' || size === 'small' ? 'small' : 'small' }
                  wrap={true}
                >
                  {
                    dataRows.map(({ id, name, stateAbbrev }) => (
                      <Card
                        key={name}
                        name={name}
                        abbrev={stateAbbrev}
                        onClick={() => toggleDetailsLayer({
                          typeDetail: 'city',
                          params: { electionId: 1, id }
                        })}
                      />
                    ))
                  }
                </Box>
              </Box>
            )}

          </Box>
        </Box>
      )}
    </ResponsiveContext.Consumer>
  )
}

const mapStateToProps = ({ searchReducer }) => ({
  dataRows: searchReducer.dataRows,
  loading: searchReducer.loading,
  error: searchReducer.error,
  filtered: searchReducer.filtered,
});

const mapDispatchToProps = dispatch =>
  bindActionCreators({
    openLayer,
    toggleDetailsLayer,
  }, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(SearchResult);
