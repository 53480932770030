import React from 'react';
import { Box, Text, Anchor, Button, ResponsiveContext, Paragraph } from 'grommet';

const ErrorDetails = (props) => {
  const {
    icon = null,
    title = null,
    message = null,
    link = null,
    button = null,
  } = props;

  return (
    <ResponsiveContext.Consumer>
      {size => (
        <Box
          direction='column'
          align='center'
          justify='center'
          margin={{ top: 'medium' }}
          height='100vh'
        >
          <Box
            align='center'
            width='medium'
            pad={ size === 'small' ? 'large' : 'small' }
          >
            {icon && (<Box margin={{ top: 'small', bottom: 'small' }}>
              {icon}
            </Box>)}

            {title && (<Box margin={{ bottom: 'small' }}>
              <Text size='xlarge' textAlign='center'>
                {title}
              </Text>
            </Box>)}

            {message && (<Box margin={{ top: 'none', bottom: 'medium' }}>
              <Paragraph size='small' color='dark-5' textAlign='center'>
                {message}
              </Paragraph>
            </Box>)}

            {link && (<Box margin={{ top: 'none' }}>
              <Anchor
                href={link.href}
                label={link.label}
                size='medium'
                primary
              />
            </Box>)}

            {button && (<Box margin={{ top: 'none' }}>
                <Button
                  label={button.label}
                  primary={true}
                  onClick={() => { button.action() }}
                />
              </Box>
            )}
          </Box>
        </Box>
      )}
    </ResponsiveContext.Consumer>
  )
}

export default ErrorDetails;
