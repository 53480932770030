import React from 'react';
import { Box, Text, ResponsiveContext } from 'grommet';
import { Checkmark } from 'grommet-icons';

const Step = ({ index, description, icon }) => {
  return (
    <ResponsiveContext.Consumer>
      {size => (
        <Box
          round={ size === 'small' ? 'small' : 'xsmall'}
          align='center'
          direction='row'
          gap={ size === 'small' ? 'medium' : 'small' }
        >
          {index && (
            <Text color='dark-2' size='medium' weight='bold'>{index}.</Text>
          )}

          {icon && (
            <Checkmark color='dark-2' size='small' />
          )}

          <Text color='dark-2' size='small'>
            {description}
          </Text>
        </Box>
      )}
    </ResponsiveContext.Consumer>
  )
}

export { Step };
