const TYPES = ({
  getStatusServiceOptions: 'GET_STATUS_SERVICE_QUERIES',
  getOriginServiceOptions: 'GET_ORIGIN_SERVICE_QUERIES',
  getCategoryServiceOptions: 'GET_CATEGORY_SERVICE_QUERIES',
  getGroupsOptions: 'GET_GROUPS_QUERIES',
  getCityOptions: 'GET_CITY_QUERIES',
  getDistrictOptions: 'GET_DISTRICT_QUERIES',
  getPartiesOptions: 'GET_PARTIES_QUERIES',
  getStatesOptions: 'GET_STATES_QUERIES',

  formChangeValues: 'FORM_CHANGE_VALUES_GENERIC',
  formInitializeValues: 'FORM_INITIALIZE_VALUES_GENERIC',
  formCleanValues: 'FORM_CLEAR_VALUES_GENERIC',

  searchLoading: 'SEARCH_LOADING',
  searchSuccess: 'SEARCH_SUCCESS',
  searchError: 'SEARCH_ERROR',
  searchReset: 'SEARCH_RESET',

  toggleLayer: 'TOGGLE_LAYER',

  detailsGetResult: 'DETAILS_GET',
  detailsGetError: 'DETAILS_ERROR',
});

export default TYPES;
