import TYPES from './types';
import { getCandidateVoting, getCityVoting } from '../api/election';

const searchDetails = ({ TP, params }) => {
  switch (TP) {
    case 'candidate':
      return getCandidateVoting({ values: params })
        .then(response => {
          return {
            type: TYPES.detailsGetResult,
            payload: response,
          }
        })
        .catch(() => ({ type: TYPES.detailsGetError }))

    case 'city':
      return getCityVoting({ values: params })
        .then(response => {
          return {
            type: TYPES.detailsGetResult,
            payload: response,
          }
        })
        .catch(() => ({ type: TYPES.detailsGetError }))

    default:
      return {
        type: TYPES.detailsGetResult,
        payload: null,
      }
  }
}

export const toggleDetailsLayer = ({ typeDetail, params }) => {
  if(params) {
    return [
      { type: TYPES.toggleLayer, payload: 'details' },
      searchDetails({ TP: typeDetail, params }),
    ]
  }

  return [
    { type: TYPES.toggleLayer, payload: null },
    { type: TYPES.detailsGetResult, payload: null }
  ];
};
