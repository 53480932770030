const urlRoutes = ({
  home: '/system',

  voters: '/voters',
  services: '/services',
  candidates: '/elections',
  cities: '/cities',

  login: 'https://app.gerenciameumandato.com.br/auth/login',
});

export default urlRoutes;
