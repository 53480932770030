import React from 'react';
import { ResponsiveContext, Box, Text } from 'grommet';

const Tag = ({ text, color }) =>  (
  <ResponsiveContext.Consumer>{size => (
    <Box
      background={color}
      direction='row'
      round='small'
      pad={{
        horizontal: size === 'small' ? 'small' : 'small',
        vertical: size === 'small' ? 'xxsmall' : 'none',
      }}
    >
      <Text
        title={text}
        truncate
        color='white'
        size='small'
      >
        {text}
      </Text>
    </Box>
  )}</ResponsiveContext.Consumer>
)

export default Tag;
