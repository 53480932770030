import TYPES from './types';
import { queryCommon } from '../api/queries';
import { cities, districts } from '../api/location';

export const getStatusServiceOptions = () => {
  return async dispatch => {
    await queryCommon('treatmentStatusList')
      .then(response =>
        dispatch({
          type: TYPES.getStatusServiceOptions,
          payload: response.map(({ treatmentStatusId, statusName }) => (
            {
              id: treatmentStatusId,
              name: statusName,
            }
          )),
        })
      )
  }
}

export const getOriginsServiceOptions = () => {
  return dispatch => {
    queryCommon('treatmentOriginList')
      .then(response => {
        dispatch({
          type: TYPES.getOriginServiceOptions,
          payload: response.map(({ id, origin }) => ({
            id,
            name: origin,
          })),
        })
      })
  }
}

export const getCategoriesServiceOptions = () => {
  return dispatch => {
    queryCommon('treatmentCategoryList')
      .then(response => {
        dispatch({
          type: TYPES.getCategoryServiceOptions,
          payload: response.map(({ treatmentCategoryId, category }) => (
            {
              id: treatmentCategoryId,
              name: category,
            }
          )),
        })
      })
  }
}

export const getGroupsOptions = () => {
  return dispatch => {
    queryCommon('electorGroupList')
      .then(response => {
        dispatch({
          type: TYPES.getGroupsOptions,
          payload: response.map(({ id, groupName }) => (
            {
              id,
              name: groupName,
            }
          )),
        })
      })
  }
}

export const searchCityOptions = ({ name }) => {
  return dispatch => {
    cities({ name })
      .then(response => {
        dispatch({
          type: TYPES.getCityOptions,
          payload: response.map(result => {
            const {
              id,
              name,
              stateAbbrev,
              stateId,
            } = result;

            return {
              id,
              stateId,
              name,
              stateAbbrev
            }
          }),
        })
      })
  }
};

export const searchDistrictOptions = ({ cityId, name }) => {
  return dispatch => {
    districts({ cityId, name })
      .then(response => {
        dispatch({
          type: TYPES.getDistrictOptions,
          payload: response.data.map(result => {
            const {
              id,
              district,
            } = result;

            return {
              id,
              name: district,
            }
          }),
        })
      })
  }
};

export const getPartiesOptions = () => {
  return dispatch => {
    queryCommon('partyList')
      .then(response => {
        dispatch({
          type: TYPES.getPartiesOptions,
          payload: response.map(({ id, acronym }) => (
            {
              id,
              name: acronym,
            }
          )),
        })
      })
  }
}

export const getStateOptions = () => {
  return dispatch => {
    queryCommon('stateList')
      .then(response => {
        dispatch({
          type: TYPES.getStatesOptions,
          payload: response.map(({ id, state }) => (
            {
              id,
              name: state,
            }
          )),
        })
      })
  }
}
