import React from 'react';
import { connect } from 'react-redux';
import { ResponsiveContext, Box, Text } from 'grommet';
import { Card } from './card';
import { Step } from './step';

const Result = (props) => {
  const {
    // data
    dataRows,
    loading,
    error
  } = props;

  if(loading && !error) {
    return
  }

  const totalVoters = dataRows.find(a => a.type === 'voter')?.total || 0;
  const totalServices = dataRows.find(a => a.type === 'service')?.total || 0;

  return (
    <ResponsiveContext.Consumer>
      {size => (
        <Box
          direction='row'
          justify='center'
          margin={{ horizontal: 'medium', top: '100px' }}
        >
          <Box
            pad='small'
            width='xlarge'
          >
            <Box
              direction='row'
              gap={ size === 'small' ? 'small' : 'large' }
              align='start'
              justify='between'
            >
              {/* primeira coluna */}
              <Box
                gap={ size === 'small' ? 'small' : 'medium' }
                width={ size === 'small' || size === 'medium' ? 'xlarge' : 'large' }
              >
                {/* O que é */}
                <Box
                  gap={ size === 'small' ? 'medium' : 'small' }
                >
                  <Text color='brand' size='large' weight='bold'>O que é o Analytics?</Text>

                  <Text color='dark-2' size='small'>
                    É uma ferramenta que te ajuda a tomar decisões baseando-se em dados.
                  </Text>

                  <Text color='dark-2' size='small'>
                    Com ela é possível extrair diversos dados baseado nas informações atuais dos seus cadastros.
                  </Text>
                </Box>


                {/* Como funciona */}
                <Box
                  gap={ size === 'small' ? 'medium' : 'small' }
                >
                  <Text color='dark-1' size='medium'>Como funciona?</Text>

                  <Step
                    index={1}
                    description='Cadastre e mantenha os dados sempre atualizados e o mais completo possível.'
                  />

                  <Step
                    index={2}
                    description='Acesse as estatísticas, filtre e descobra como estão as informações do sistema.'
                  />
                </Box>

                {/* O que fazer */}
                <Box
                  gap={ size === 'small' ? 'medium' : 'small' }
                >
                  <Text color='dark-1' size='medium'>O que é possível?</Text>

                  <Step description='Saber quantos votos já conquistou e quanto ainda precisa conquistar.' />
                  <Step description='Saber quantos atendimentos cadastrados de uma determinada cidade ou bairro.' />
                  <Step description='E muito mais!' />
                </Box>
              </Box>

              {/* Separador coluna */}
              {<Box
                width={ size === 'large' || size === 'xlarge' ? 'xsmall' : 'xxsmall'}
              />}

              {/* segunda coluna */}
              <Box
                width={ size === 'small' || size === 'medium' ? 'xlarge' : 'large' }
                align='end'
              >
                <Box
                  direction='row'
                  gap={ size === 'small' ? 'xsmall' : 'medium' }
                >
                  <Card name='Total de Eleitores' total={totalVoters} />
                  <Card name='Total de Atendimentos' total={totalServices} />
                </Box>
              </Box>
            </Box>
          </Box>
        </Box>
      )}
    </ResponsiveContext.Consumer>
  )
};

const mapStateToProps = ({ searchReducer }) => ({
  dataRows: searchReducer.dataRows,
  loading: searchReducer.loading,
  error: searchReducer.error
});

const mapDispatchToProps = null;

export default connect(mapStateToProps, mapDispatchToProps)(Result);
