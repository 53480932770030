import React from 'react';
import { ResponsiveContext, Box, Layer, Text, Button } from 'grommet';
import { FormClose, HomeRounded } from 'grommet-icons';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { LoadDetails, ErrorDetails, Tag } from '../../../components';
import { toggleDetailsLayer } from '../../../actions/details';

import {
  formattedNumber,
  formattedPercentage,
  getCookie,
  decryptAes,
} from '../../../utils';

import sideBar from '../../../constants/sidebar';

const performance = ({ schools }) => {
  const schoolsOrdered = schools.sort((a, b) => b.amountVotes - a.amountVotes);

  return {
    highest: schoolsOrdered[0],
    lowest: schoolsOrdered[schoolsOrdered.length - 1],
  }
};

const findCity = ({ cities = [], cityId }) => {
  return cities.find(a => a.cityId === cityId)?.city || '';
};

const findDistrict = ({ districts = [], districtId }) => {
  return districts.find(a => a.districtId === districtId)?.district || '';
};

const Details = (props) => {
  if(!props.toggleLayer || props.toggleLayer !== 'details') return null;

  const {
    // fields
    detail,
    detailError,

    // functions
    toggleDetailsLayer,
  } = props;


  const performanceResult = detail ? performance({
      schools: detail.schools,
    }) : null;

  const userLoggedState = getCookie();
  const decipher = decryptAes(userLoggedState);

  const isTrialUser = !!decipher?.client?.trl;

  return (
    <ResponsiveContext.Consumer>
      {size => (
        <Layer
          responsive={false}
          full='vertical'
          position='left'
          onClickOutside={toggleDetailsLayer}
          onEsc={toggleDetailsLayer}
        >
          <Box
            fill='vertical'
            width={sideBar[size]}
            overflow='auto'
          >
            {detailError ? (
              <ErrorDetails
                title='Ops! Algo deu errado :('
                message='Estamos constrangidos por isso.'
                button={{
                  label: 'Fechar',
                  action: toggleDetailsLayer,
                }}
              />
            ) : null}

            {!detail && !detailError ? (<LoadDetails />) : null}

            {detail ? (
              <>
                {isTrialUser && (
                  <Box
                    background='status-warning'
                    pad={{ horizontal: 'large', vertical: 'xsmall' }}
                    flex={{ shrink: 0 }}
                  >
                    <Text size='small' color='white'>
                      Os dados abaixo são uma prévia, pois você está em período de avaliação do sistema.
                    </Text>
                  </Box>)
                }

                <Box
                  margin={{
                    horizontal: 'large',
                  }}
                  flex={{ shrink: 0 }}
                  pad={{ vertical: size === 'small' ? 'xlarge' : 'large' }}
                >
                  {/* Cabeçalho */}
                  <Box
                    direction='row'
                    justify='between'
                  >
                    <Box
                      direction='row'
                      gap='small'
                      align='baseline'
                    >
                      <Box>
                        <Text
                          size={ size === 'small' ? 'large' : 'xlarge' }
                          textAlign='start'
                        >
                          {detail.candidateElection.name}
                        </Text>
                      </Box>

                      <Box>
                        <Tag
                          text={detail.candidateElection.is ? 'eleitor' : 'não eleitor'}
                          color={detail.candidateElection.is ? 'status-ok' : 'light-6'}
                        />
                      </Box>
                    </Box>

                    <Button
                      plain
                      title='Fechar'
                      icon={<FormClose />}
                      onClick={toggleDetailsLayer}
                    />
                  </Box>


                  { /* Candidato Dados */ }
                  <Box
                    margin={{ vertical: 'medium' }}
                    gap='xsmall'
                  >
                    <Box
                      direction='row'
                      gap='small'
                      align='baseline'
                    >
                      <Text size='small' color='dark-5'>Escolaridade</Text>
                      <Text size='small' color='dark-2'>{detail.candidate.education}</Text>
                    </Box>

                    <Box
                      direction='row'
                      gap='small'
                      align='baseline'
                    >
                      <Text size='small' color='dark-5'>Gênero</Text>
                      <Text size='small' color='dark-2'>{detail.candidate.gender}</Text>
                    </Box>

                    <Box
                      direction='row'
                      gap='small'
                      align='baseline'
                    >
                      <Text size='small' color='dark-5'>Estado Civil</Text>
                      <Text size='small' color='dark-2'>{detail.candidate.maritalStatus}</Text>
                    </Box>
                  </Box>


                  { /* Eleição candidato Dados */ }
                  <Box
                    margin={{ top: 'medium' }}
                    gap='xsmall'
                  >
                    <Text
                      size={ size === 'small' ? 'small' : 'medium' }
                      color='dark-2'
                      textAlign='start'
                    >
                      Eleição
                    </Text>
                  </Box>

                  <Box
                    margin={{ top: 'medium' }}
                    direction={ size === 'xsmall' || size === 'small' ? 'column' : 'row'}
                    gap='xsmall'
                  >
                    {/* Situação */}
                    <Box
                      round='xsmall'
                      width={ size === 'xsmall' || size === 'small' ? 'large' : 'small' }
                      pad={{
                        vertical: size === 'small' ? 'large' : 'small',
                        horizontal: size === 'small' ? 'large' : 'small',
                      }}
                      border={{ color: 'light-6' }}
                      margin={{ bottom: 'small' }}
                      gap='large'
                      direction={ size === 'xsmall' || size === 'small' ? 'row' : 'column' }
                      justify={
                        size === 'xsmall' || size === 'small' ? 'between' : undefined
                      }
                    >
                      <Text size='small' color='dark-2'>Situação</Text>

                      <Box
                        direction='row'
                      >
                        <Tag
                          text={detail.candidateElection.situation}
                          color={detail.candidateElection.is ? 'status-ok' : 'dark-5'}
                        />
                      </Box>
                    </Box>

                    {/* Partido */}
                    <Box
                      round='xsmall'
                      width={ size === 'xsmall' || size === 'small' ? 'large' : 'small' }
                      pad={{
                        vertical: size === 'small' ? 'large' : 'small',
                        horizontal: size === 'small' ? 'large' : 'small',
                      }}
                      border={{ color: 'light-6' }}
                      margin={{ bottom: 'small' }}
                      gap='large'
                      direction={ size === 'xsmall' || size === 'small' ? 'row' : 'column' }
                      justify={
                        size === 'xsmall' || size === 'small' ? 'between' : undefined
                      }
                    >
                      <Text size='small' color='dark-2'>Partido</Text>

                      <Box
                        direction='row'
                      >
                        <Tag
                          text={detail.candidateElection.partyAcronym}
                          color={detail.candidateElection.partyColor}
                        />
                      </Box>
                    </Box>

                    {/* Total de votos */}
                    <Box
                      round='xsmall'
                      width={ size === 'xsmall' || size === 'small' ? 'large' : 'small' }
                      pad={{
                        vertical: size === 'small' ? 'large' : 'small',
                        horizontal: size === 'small' ? 'large' : 'small',
                      }}
                      border={{ color: 'light-6' }}
                      margin={{ bottom: 'small' }}
                      gap='large'
                      direction={ size === 'xsmall' || size === 'small' ? 'row' : 'column' }
                      justify={
                        size === 'xsmall' || size === 'small' ? 'between' : undefined
                      }
                    >
                      <Text size='small' color='dark-2'>Total de votos</Text>

                      <Box
                        direction='row'
                      >
                        <Tag
                          text={formattedNumber(detail.candidateElection.amountVotes)}
                          color='dark-1'
                        />
                      </Box>
                    </Box>
                  </Box>


                  { /* Desempenho candidato Dados */ }
                  {performanceResult.highest && performanceResult.lowest ? (
                    <>
                      <Box
                        margin={{ top: size === 'xsmall' || size === 'small' ? 'large' : 'medium' }}
                        gap='xsmall'
                      >
                        <Text
                          size={ size === 'small' ? 'small' : 'medium' }
                          color='dark-2'
                          textAlign='start'
                        >
                          Desempenho
                        </Text>
                      </Box>

                      <Box
                        margin={{ top: 'medium' }}
                        direction={ size === 'xsmall' || size === 'small' ? 'column' : 'row'}
                        gap='xsmall'
                      >
                        {/* Maior voto */}
                        <Box
                          round='xsmall'
                          width={ size === 'xsmall' || size === 'small' ? 'large' : 'small' }
                          pad={{
                            vertical: size === 'small' ? 'large' : 'small',
                            horizontal: size === 'small' ? 'large' : 'small',
                          }}
                          border={{ color: 'light-6' }}
                          margin={{ bottom: 'small' }}
                          gap='medium'
                        >
                          <Text size='small' color='dark-2'>Maior votação</Text>

                          <Box>
                            <Text size='xsmall' color='dark-1'>
                              {
                                formattedNumber(performanceResult.highest.amountVotes)
                              } voto(s)
                            </Text>

                            <Text size='medium' color='dark-1'>
                              {performanceResult.highest.school}
                            </Text>

                            <Text size='xsmall' color='dark-6'>
                              {
                                findCity({ cities: detail.cities, cityId: performanceResult.highest.cityId })
                              }
                            </Text>
                          </Box>
                        </Box>

                        {/* Menor voto */}
                        <Box
                          round='xsmall'
                          width={ size === 'xsmall' || size === 'small' ? 'large' : 'small' }
                          pad={{
                            vertical: size === 'small' ? 'large' : 'small',
                            horizontal: size === 'small' ? 'large' : 'small',
                          }}
                          border={{ color: 'light-6' }}
                          margin={{ bottom: 'small' }}
                          gap='medium'
                        >
                          <Text size='small' color='dark-2'>Menor votação</Text>

                          <Box>
                            <Text size='xsmall' color='dark-1'>
                              {
                                formattedNumber(performanceResult.lowest.amountVotes)
                              } voto(s)
                            </Text>

                            <Text size='medium' color='dark-1'>
                              {performanceResult.lowest.school}
                            </Text>

                            <Text size='xsmall' color='dark-6'>
                              {
                                findCity({ cities: detail.cities, cityId: performanceResult.lowest.cityId })
                              }
                            </Text>
                          </Box>
                        </Box>
                      </Box>
                    </>
                  ) : null}


                  { /* Votação por bairro */ }
                  {detail.districts.length ? (
                    <>
                      <Box
                        margin={{ top: size === 'xsmall' || size === 'small' ? 'large' : 'medium' }}
                        gap='xsmall'
                      >
                        <Text
                          size={ size === 'small' ? 'small' : 'medium' }
                          color='dark-2'
                          textAlign='start'
                        >
                          Votos por bairro
                        </Text>
                      </Box>

                      <Box
                        margin={{ top: 'medium' }}
                        direction={ size === 'xsmall' || size === 'small' ? 'column' : 'row'}
                        gap='small'
                        wrap={true}
                      >
                        {
                          detail.districts
                            .sort((a, b) => b.amountVotes - a.amountVotes)
                            .slice(
                              0, isTrialUser ? 5 : detail.districts.length
                            )
                            .map(({ district, cityId, amountVotes }) => {
                              return (
                                <Box
                                  key={district}
                                  round='xsmall'
                                  pad={{
                                    vertical: size === 'small' ? 'large' : 'small',
                                    horizontal: size === 'small' ? 'large' : 'small',
                                  }}
                                  border={{ color: 'light-6' }}
                                  margin={{ bottom: 'small' }}
                                  gap='medium'
                                  direction={ size === 'xsmall' || size === 'small' ? 'row-reverse' : 'column' }
                                  justify='between'
                                >
                                  <Box>
                                    <Text
                                      size='small'
                                      color='dark-1'
                                    >
                                      {formattedNumber(amountVotes)} voto(s)
                                    </Text>

                                    <Text
                                      size='xsmall'
                                      color={detail?.candidateElection?.partyColor || 'dark-1'}
                                    >
                                      {formattedPercentage(
                                        detail.candidateElection.amountVotes, amountVotes
                                      )}
                                    </Text>
                                  </Box>

                                  <Box>
                                    <Text size='xsmall' color='dark-1'>{district}</Text>

                                    <Text size='xsmall' color='dark-6'>
                                      {findCity({ cities: detail.cities, cityId })}
                                    </Text>
                                  </Box>
                                </Box>
                              )
                          })
                        }
                      </Box>
                    </>
                  ) : null}


                  { /* Votação por escola */ }
                  {detail.schools.length ? (
                    <>
                      <Box
                        margin={{ top: size === 'xsmall' || size === 'small' ? 'large' : 'medium' }}
                        gap='xsmall'
                      >
                        <Text
                          size={ size === 'small' ? 'small' : 'medium' }
                          color='dark-2'
                          textAlign='start'
                        >
                          Votos por local de votação
                        </Text>
                      </Box>

                      <Box
                        margin={{ top: 'medium' }}
                        round='xsmall'
                        border={{ color: 'light-6' }}
                      >
                        <Box
                          pad={{
                            horizontal: size === 'xsmall' || size === 'small' ? 'medium' : 'medium',
                            vertical: size === 'xsmall' || size === 'small' ? 'large' : 'medium',
                          }}
                          direction='row'
                          gap='medium'
                          justify='between'
                          align='center'
                        >
                          <HomeRounded color={detail.candidateElection.partyColor} />

                          <Box
                            direction='row'
                            gap='medium'
                          >
                            <Box
                              align='baseline'
                            >
                              <Text size='small'>escolas</Text>
                              <Text color={detail.candidateElection.partyColor} size='large'>{detail.schoolsSummary.totalSchools}</Text>
                            </Box>

                            <Box
                              align='baseline'
                            >
                              <Text size='small'>escolas com votação</Text>
                              <Text color={detail.candidateElection.partyColor} size='large'>{detail.schools.length}</Text>
                            </Box>

                            <Box
                              align='baseline'
                            >
                              <Text size='small'>zona(s)</Text>
                              <Text color={detail.candidateElection.partyColor} size='large'>{detail.schoolsSummary.totalZones}</Text>
                            </Box>
                          </Box>
                        </Box>

                        <Box
                          direction='column'
                        >
                          {
                            detail.schools
                              .sort((a, b) => b.amountVotes - a.amountVotes)
                              .slice(
                                0, isTrialUser ? 5 : detail.schools.length
                              )
                              .map(({ school, amountVotes, cityId, districtId }) => {
                              return (
                                <Box
                                  key={school}
                                  flex={{ shrink: 0 }}
                                  pad={{
                                    horizontal: size === 'xsmall' || size === 'small' ? 'medium' : 'medium',
                                    vertical: size === 'xsmall' || size === 'small' ? 'medium' : 'small',
                                  }}
                                  border={{
                                    side: 'bottom',
                                    color: 'light-6',
                                  }}
                                >
                                  <Box
                                    direction='row'
                                    gap='small'
                                    justify='between'
                                    align='center'
                                  >
                                    <Box>
                                      <Text size='small' color='dark-1'>{school}</Text>

                                      <Box
                                        direction='row'
                                        gap='xsmall'
                                        align='center'
                                      >
                                        <Text size='xsmall' color='dark-3'>{
                                          findDistrict({
                                            districts: detail.districts,
                                            districtId,
                                          })
                                        }</Text>

                                        <Box
                                          round='large'
                                          background='light-6'
                                          width='5px'
                                          height='5px'
                                        />

                                        <Text size='xsmall' color='dark-3'>{
                                          findCity({
                                            cities: detail.cities,
                                            cityId,
                                          })
                                        }</Text>
                                      </Box>
                                    </Box>

                                    <Box
                                      align='end'
                                    >
                                      <Text size='small' color='dark-1'>
                                        {formattedNumber(amountVotes)} voto(s)
                                      </Text>

                                      <Text size='xsmall' color={detail?.candidateElection?.partyColor || 'dark-1'}>
                                        {formattedPercentage(
                                          detail.candidateElection.amountVotes, amountVotes
                                        )}
                                      </Text>
                                    </Box>
                                  </Box>
                                </Box>
                              )
                            })
                          }
                        </Box>
                      </Box>
                    </>
                  ) : null}

                </Box>
            </>) : null}

          </Box>
        </Layer>
      )}
    </ResponsiveContext.Consumer>
  )
}

const mapStateToProps = ({ layerReducer, layerDetailsReducer }) => ({
  toggleLayer: layerReducer.toggleLayer,
  detail: layerDetailsReducer.detail,
  detailError: layerDetailsReducer.detailError,
});

const mapDispatchToProps = dispatch =>
  bindActionCreators({
    toggleDetailsLayer,
  }, dispatch)

export default connect(mapStateToProps, mapDispatchToProps)(Details);
