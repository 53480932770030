import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import Details from '../components/details';
import SearchResult from '../components/search-result';
import FormSearch from '../components/form-search';

import { Header, RequireAuth } from '../../../components';

import { getStateOptions } from '../../../actions/select-options';
import { resetSearch } from '../../../actions/search-election';

const DashboardCity = (props) => {
  const [render, renderComponent] = useState(false);

  useEffect(() => {
    props.getStateOptions();
  }, [props]);

  useEffect(() => {
    const { resetSearch } = props;

    resetSearch();
    renderComponent(true);
  }, [props]);

  return (
    <>
      <Header />

      {render && (
        <>
          <SearchResult />
          <Details />
          <FormSearch />
        </>
      )}
    </>
  )
}

const mapStateToProps = null

const mapDispatchToProps = dispath =>
  bindActionCreators({
    getStateOptions,
    resetSearch,
  }, dispath)

export default RequireAuth(connect(mapStateToProps, mapDispatchToProps)(DashboardCity));
