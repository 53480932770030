import TYPES from '../actions/types';

const INITIAL_STATE = {
  detail: null,
  detailError: false,
}

const layerDetailsReducer = (state = INITIAL_STATE, action) => {
  switch(action.type) {
    case TYPES.detailsGetResult:
      return {
        ...state,
        detail: action.payload,
        detailError: false,
      };

    case TYPES.detailsGetError:
      return { ...state, detailError: true };

    default:
      return state;
  }
}

export { layerDetailsReducer }
