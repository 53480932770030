import TYPES from './types';
import { list } from '../api/search';
import { voters, services } from '../api/dashboard';

const searchLoadToggle = () => ({ type: TYPES.searchLoading });

export const search = ({ path, values }) => {
  return dispatch => {
    dispatch(searchLoadToggle());

    list({ path, values })
      .then(response =>
        dispatch({
          type: TYPES.searchSuccess,
          payload: response,
        })
      )
      .then(() => dispatch(searchLoadToggle()))
      .catch(() => dispatch({ type: TYPES.searchError }))
  }
};

export const getDashboard = () => {
  return async dispatch => {
    dispatch(searchLoadToggle());

    const [voter, service] = await Promise.all([
      voters(),
      services()
    ]);

    const totalVoter = voter.reduce((acc, { total }) => acc + total, 0);
    const totalService = service.reduce((acc, { total }) => acc + total, 0);

    dispatch({
      type: TYPES.searchSuccess,
      payload: [
        { type: 'voter', total: totalVoter },
        { type: 'service', total: totalService }
      ]
    });

    dispatch(searchLoadToggle());
  }
};

export const resetSearch = () => ({ type: TYPES.searchReset });
