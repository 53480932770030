import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import SearchResult from '../components/search-result';
import FormSearch from '../components/form-search';

import { Header, RequireAuth } from '../../../components';

import { getGroupsOptions } from '../../../actions/select-options';
import { search, resetSearch } from '../../../actions/search';

const DashboardVoter = (props) => {
  const [render, renderComponent] = useState(false);

  useEffect(() => {
    const { getGroupsOptions, search } = props;

    resetSearch();
    renderComponent(true);

    search({
      path: 'eleitor.estatistica.php',
      values: {}
    });

    getGroupsOptions();

  }, [props]);

  return (
    <>
      <Header />
      {render && (<><SearchResult /><FormSearch /></>)}
    </>
  )
}

const mapStateToProps = null

const mapDispatchToProps = dispath =>
  bindActionCreators({
    getGroupsOptions,
    search,
    resetSearch,
  }, dispath)

export default RequireAuth(connect(mapStateToProps, mapDispatchToProps)(DashboardVoter));
