import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { ResponsiveContext, Box, Anchor } from 'grommet';

import optionsMenu from '../config/options-menu';

const Header = () => {
  const navigate = useNavigate();
  const location = useLocation();

  const [systemType, setSystemType] = useState('');

  useEffect(() => {
    setSystemType(
      location.pathname === '/elections' || location.pathname === '/cities' ? 'elections' : 'system'
    );
  }, [location]);

  return (
    <ResponsiveContext.Consumer>
      {size => (
        <Box
          pad='xsmall'
          direction={ size === 'small' ? 'column' : 'row' }
          justify='center'
          margin={{
            bottom: size === 'small' ? 'large' : 'medium'
          }}
          background='#2f5878'
          elevation='xsmall'
          width='100vw'
          height='60px'
          style={{
            zIndex: 1,
            position: 'fixed',
            top: 0,
          }}
        >
          <Box
            direction='row'
            align='center'
            width={ size === 'small' ? '100vw' : '95vw' }
            pad={{ horizontal: 'medium' }}
            justify='between'
          >
            <Box
              direction='row'
              align='center'
              gap='medium'
            >
              <Anchor
                size='medium'
                label={
                  systemType === 'elections' ? 'Gerencia Meu Mandato | Eleições' : 'Gerencia Meu Mandato | Analytics'
                }
                color='white'
                onClick={() =>
                  navigate(
                    optionsMenu.find(a => a.type === systemType).click, {
                      state: { type: optionsMenu.find(a => a.type === systemType).type }
                    }
                  )
                }
              />


              <Box
                direction='row'
                gap='medium'
                align='center'
              >
                <Box
                  direction='row'
                  gap='medium'
                  align='center'
                >
                  {
                    optionsMenu
                      .filter(
                        item => item.showMenu && item.type === systemType
                      )
                      .map(item => {
                        return (
                          <Anchor
                            key={item.label}
                            label={item.label}
                            color='white'
                            size='small'
                            onClick={() => navigate(item.click, { state: { type: item.type } })}
                          />
                        )
                    })
                  }
                </Box>
              </Box>
            </Box>
          </Box>
        </Box>
      )}
    </ResponsiveContext.Consumer>
  )
}

export default Header;
