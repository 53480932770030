import TYPES from '../actions/types';

const INITIAL_STATE = {
  loading: false,
  error: false,
  dataRows: [],
  filtered: false,
}

const searchReducer = (state = INITIAL_STATE, action) => {
  const acType = TYPES[action.type] ?? action.type;

  switch(acType) {
    case TYPES.searchSuccess:
      return {
        ...state,
        dataRows: action.payload,
        error: false,
        filtered: true,
      };

    case TYPES.searchLoading:
      return {
        ...state,
        error: false,
        loading: !state.loading,
      };

    case TYPES.searchError:
      return {
        ...state,
        error: true,
        loading: !state.loading,
      }

    case TYPES.searchReset:
      return {
        ...state,
        dataRows: [],
        error: false,
        loading: false,
        filtered: false,
      }

    default:
      return state;
  }
}

export { searchReducer }
