import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import Result from '../components/result';
import { Header, RequireAuth } from '../../../components';

import { getDashboard, resetSearch } from '../../../actions/search';

const DashboardSystem = (props) => {
  const [render, renderComponent] = useState(false);

  useEffect(() => {
    const {
      getDashboard
    } = props;

    resetSearch();
    renderComponent(true);

    getDashboard({
      path: 'dashboard.php',
      values: {}
    });

  }, [props]);

  return (
    <>
      <Header />
      {render && <Result />}
    </>
  )
}

const mapStateToProps = null;

const mapDispatchToProps = dispatch => {
  return bindActionCreators({
    getDashboard,
    resetSearch,
  }, dispatch)
};

export default RequireAuth(connect(mapStateToProps, mapDispatchToProps)(DashboardSystem));
