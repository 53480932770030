import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import SearchResult from '../components/search-result';
import FormSearch from '../components/form-search';
import { Header } from '../../../components';

import { getStatusServiceOptions, getOriginsServiceOptions, getCategoriesServiceOptions } from '../../../actions/select-options';
import { search, resetSearch } from '../../../actions/search';

const DashboardService = (props) => {
  const [render, renderComponent] = useState(false);

  useEffect(() => {
    const {
      getStatusServiceOptions, getOriginsServiceOptions, getCategoriesServiceOptions, search
    } = props;

    resetSearch();
    renderComponent(true);

    search({
      path: 'atendimento.estatistica.php',
      values: {}
    });

    getStatusServiceOptions();
    getOriginsServiceOptions();
    getCategoriesServiceOptions();
  }, [props]);

  return (
    <>
      <Header />
      {render && (<><SearchResult /><FormSearch /></>)}
    </>
  )
}

const mapStateToProps = null

const mapDispatchToProps = dispath =>
  bindActionCreators({
    getStatusServiceOptions,
    getOriginsServiceOptions,
    getCategoriesServiceOptions,
    search,
    resetSearch,
  }, dispath)

export default connect(mapStateToProps, mapDispatchToProps)(DashboardService);
