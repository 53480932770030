import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { ResponsiveContext, Box, Button, Text, Tip } from 'grommet';
import { Search, Download } from 'grommet-icons';

import { Card } from './card';
import { EmptySearches, ErrorSearches, LoadSearches } from '../../../components';

import { openLayer } from '../../../actions/layers';

import { formattedNumber, formattedPercentage } from '../../../utils';

const SearchResult = (props) => {
  const {
    // functions
    openLayer,

    //data
    dataRows,
    loading,
    error
  } = props;

  if(loading && !error) {
    return <LoadSearches />;
  }

  if(error) {
    return <ErrorSearches
      button={{
        label: 'Abrir filtros',
        action: openLayer
      }}
    />;
  }

  const searchTotal = dataRows.find(({ type }) => type === 'searchTotal')?.total || 0;

  if(searchTotal === 0) {
    return <EmptySearches
      buttonFilters={{
        label: 'Abrir filtros',
        action: openLayer,
      }}
    />;
  }

  const geralTotal = dataRows.find(({ type }) => type === 'total')?.total || 0;
  const status = dataRows.filter(({ type }) => type === 'status');
  const categories = dataRows.filter(({ type }) => type === 'category');
  const origins = dataRows.filter(({ type }) => type === 'origin');
  const districts = dataRows.filter(({ type }) => type === 'district');
  const cities = dataRows.filter(({ type }) => type === 'city');

  return (
    <ResponsiveContext.Consumer>
      {size => (
        <Box
          direction='row'
          justify='center'
          margin={{ horizontal: 'medium', top: '80px' }}
        >
          <Box
            pad='small'
            width='xlarge'
          >
            <Box
              align='center'
              direction='row'
              justify='between'
              margin={{ bottom: 'small' }}
            >
              <Box
                direction={size === 'small' ? 'column' : 'row'}
                align={size === 'small' ? 'baseline' : 'center'}
                gap={size === 'small' ? null : 'small'}
              >
                <Text color='dark-1' size='xlarge'>{searchTotal}</Text>

                <Text
                  size={size === 'small' ? 'xsmall' : 'medium'}
                  color='dark-6'
                >
                  atendimento(s)
                </Text>
              </Box>

              <Box
                direction='row'
                align='center'
                gap={size === 'small' ? 'large' : 'medium'}
              >
                <Tip
                  content={
                    <Box
                      background='light-4'
                      pad='xxsmall'
                    >
                      <Text size='xsmall' color='dark-2'>Estamos construindo essa funcionalidade, Aguarde!</Text>
                    </Box>
                  }
                  plain
                >
                  <Button
                    plain
                    //title='Download Excel (.csv)'
                    icon={<Download />}
                    size='small'
                    //onClick={() => {}}
                    //disabled
                  />
                </Tip>

                <Button
                  plain
                  title='Filtrar'
                  icon={<Search />}
                  onClick={() => { openLayer() }}
                />
              </Box>
            </Box>

            {/* Por status */}
            {!!status.length && (<Box
              gap='small'
              margin={{ vertical: 'medium' }}
            >
              <Box
                gap='xsmall'
              >
                <Text color='brand' size='medium'>Por status</Text>
              </Box>

              <Box
                direction='row'
                gap={ size === 'small' ? 'large' : 'small' }
                wrap={true}
              >
                {
                  status.map(({ name, total }) => (
                    <Card
                      key={name}
                      name={name}
                      total={formattedNumber(total)}
                      percentage={formattedPercentage(geralTotal, total)}
                    />
                  ))
                }
              </Box>
            </Box>)}

            {/* Por Genero */}
            {!!categories.length && (<Box
              gap='small'
              margin={{ vertical: 'medium' }}
            >
              <Box
                gap='xsmall'
              >
                <Text color='brand' size='medium'>Por categoria</Text>
              </Box>

              <Box
                direction='row'
                gap={ size === 'small' ? 'large' : 'small' }
                wrap={true}
              >
                {
                  categories.map(({ name, total }) => (
                    <Card
                      key={name}
                      name={name}
                      total={formattedNumber(total)}
                      percentage={formattedPercentage(geralTotal, total)}
                    />
                  ))
                }
              </Box>
            </Box>)}

            {/* Por origem */}
            {!!origins.length && (<Box
              gap='small'
              margin={{ vertical: 'medium' }}
            >
              <Box
                gap='xsmall'
              >
                <Text color='brand' size='medium'>Por origem</Text>
              </Box>

              <Box
                direction='row'
                gap={ size === 'small' ? 'large' : 'small' }
                wrap={true}
              >
                {
                  origins.map(({ name, total }) => (
                    <Card
                      key={name}
                      name={name}
                      total={formattedNumber(total)}
                      percentage={formattedPercentage(geralTotal, total)}
                    />
                  ))
                }
              </Box>
            </Box>)}

            {/* Por cidade */}
            {!!cities.length && (<Box
              gap='small'
              margin={{ vertical: 'medium' }}
            >
              <Box
                gap='xsmall'
              >
                <Text color='brand' size='medium'>Por cidade</Text>
              </Box>

              <Box
                direction='row'
                gap={ size === 'small' ? 'large' : 'small' }
                wrap={true}
              >
                {
                  cities.map(({ name, total }) => (
                    <Card
                      key={name}
                      name={name}
                      total={formattedNumber(total)}
                      percentage={formattedPercentage(geralTotal, total)}
                    />
                  ))
                }
              </Box>
            </Box>)}

            {/* Por bairro */}
            {!!districts.length && (<Box
              gap='small'
              margin={{ vertical: 'medium' }}
            >
              <Box
                gap='xsmall'
              >
                <Text color='brand' size='medium'>Por bairro</Text>
              </Box>

              <Box
                direction='row'
                gap={ size === 'small' ? 'large' : 'small' }
                wrap={true}
              >
                {
                  districts.map(({ name, total }) => (
                    <Card
                      key={name}
                      name={name}
                      total={formattedNumber(total)}
                      percentage={formattedPercentage(geralTotal, total)}
                    />
                  ))
                }
              </Box>
            </Box>)}
          </Box>
        </Box>
      )}
    </ResponsiveContext.Consumer>
  )
}

const mapStateToProps = ({ searchReducer }) => ({
  dataRows: searchReducer.dataRows,
  loading: searchReducer.loading,
  error: searchReducer.error
});

const mapDispatchToProps = dispatch =>
  bindActionCreators({ openLayer }, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(SearchResult);
