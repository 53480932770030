import React from 'react';
import { ResponsiveContext, Box, Text, Anchor } from 'grommet';

const Card = ({ name, abbrev, onClick }) => {
  return (
    <ResponsiveContext.Consumer>
      {size => (
        <Box
          round='xsmall'
          width={
            size === 'xsmall' || size === 'small' ? '100vw' : (
              size === 'medium' ? '28vw' : '23vw'
            )
          }
          pad={{
            vertical: size === 'xsmall' || size === 'small' ? 'medium' : 'small',
            horizontal: size === 'xsmall' || size === 'small' ? 'medium' : 'small',
          }}
          border={{ color: 'light-5' }}
          margin={{ bottom: 'small' }}
          gap='medium'
        >
          <Box
            direction='row'
            justify='between'
          >
            <Anchor
              title='Abrir detalhes'
              label={
                <Text truncate size='small'>
                  {name}
                </Text>
              }
              onClick={onClick}
            />

            <Text size='small' color='dark-1' weight='bolder'>{abbrev}</Text>
          </Box>
        </Box>
      )}
    </ResponsiveContext.Consumer>
  )
}

export default Card;
