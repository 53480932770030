import { getCookie, decryptAes } from '../../utils';

const getParams = (values) => {
  const params = Object.keys(values).reduce((acc, key) => {
    if (values[key]) {
      acc[key] = values[key];
    }

    return acc;
  }, {});

  return new URLSearchParams({
    ...params
  });
}

const getCityVoting = async ({ values }) => {
  const userLoggedState = getCookie();
  const decipher = decryptAes(userLoggedState);

  const response = await fetch(`${process.env.REACT_APP_HOST_API}/candidate.php?${getParams(values)}`, {
    method: 'GET',
    headers: {
      token: decipher.token.value,
      action: 'getCity',
    },
  });

  return await response.json();
}

export default getCityVoting;
