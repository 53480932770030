import urlRoutes from './routes-url';

const menuOptions = [
  {
    label: 'Início',
    type: 'system',
    click: urlRoutes.home,
    showMenu: false,
  },
  {
    label: 'Eleitores',
    type: 'system',
    click: urlRoutes.voters,
    showMenu: true,
  },
  {
    label: 'Atendimentos',
    type: 'system',
    click: urlRoutes.services,
    showMenu: true,
  },
  {
    label: 'Candidatos',
    type: 'elections',
    click: urlRoutes.candidates,
    showMenu: true,
  },
  {
    label: 'Cidades',
    type: 'elections',
    click: urlRoutes.cities,
    showMenu: true,
  }
];

export default menuOptions;
