import TYPES from './types';
import { listCandidateVoting, listCityVoting } from '../api/election';

const searchLoadToggle = () => ({ type: TYPES.searchLoading });

export const searchCandidate = ({ path, values }) => {
  return dispatch => {
    dispatch(searchLoadToggle());

    listCandidateVoting({ path, values })
      .then(response =>
        dispatch({
          type: TYPES.searchSuccess,
          payload: response,
        })
      )
      .then(() => dispatch(searchLoadToggle()))
      .catch(() => dispatch({ type: TYPES.searchError }))
  }
};

export const searchCitiesVoting = ({ path, values }) => {
  return dispatch => {
    dispatch(searchLoadToggle());

    listCityVoting({ path, values })
      .then(response =>
        dispatch({
          type: TYPES.searchSuccess,
          payload: response,
        })
      )
      .then(() => dispatch(searchLoadToggle()))
      .catch(() => dispatch({ type: TYPES.searchError }))
  }
};

export const resetSearch = () => ({ type: TYPES.searchReset });
