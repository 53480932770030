import { useEffect } from 'react';
import { getCookie, decryptAes } from '../utils';

import urlRoutes from '../config/routes-url';

const RequireAuth = (ComposedComponent) => {
  const RequireAuthComponent = (props) => {
    useEffect(() =>
    {
      const userLoggedState = getCookie();
      const decipher = decryptAes(userLoggedState);

      if(!userLoggedState || !decipher) {
        window.location = urlRoutes.login;
      }

      const isValid = new Date().getTime() < new Date(decipher.token.expires).getTime();

      if(!isValid) {
        window.location = urlRoutes.login;
      }
    });

    return <ComposedComponent {...props} />
  }

  return RequireAuthComponent;
}

export default RequireAuth;
