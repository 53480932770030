import React from 'react';
import { ResponsiveContext, Box, Layer, Text, Button } from 'grommet';
import { FormClose } from 'grommet-icons';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { LoadDetails, ErrorDetails, Tag } from '../../../components';
import { toggleDetailsLayer } from '../../../actions/details';

import {
  formattedNumber,
  getCookie,
  decryptAes,
} from '../../../utils';

import sideBar from '../../../constants/sidebar';

const Details = (props) => {
  if(!props.toggleLayer || props.toggleLayer !== 'details') return null;

  const {
    // fields
    detail,
    detailError,

    // functions
    toggleDetailsLayer,
  } = props;


  const votesMayors = detail?.cityVotes.find(a => a.candidatePositionId === 2) || null;
  const votesCouncilors = detail?.cityVotes.find(a => a.candidatePositionId === 1) || null;

  const mayors = detail?.candidates.filter(a => a.candidatePositionId === 2) || null;
  const councilors = detail?.candidates.filter(a => a.candidatePositionId === 1) || null;


  const userLoggedState = getCookie();
  const decipher = decryptAes(userLoggedState);

  return (
    <ResponsiveContext.Consumer>
      {size => (
        <Layer
          responsive={false}
          full='vertical'
          position='left'
          onClickOutside={toggleDetailsLayer}
          onEsc={toggleDetailsLayer}
        >
          <Box
            fill='vertical'
            width={sideBar[size]}
            overflow='auto'
          >
            {detailError ? (
              <ErrorDetails
                title='Ops! Algo deu errado :('
                message='Estamos constrangidos por isso.'
                button={{
                  label: 'Fechar',
                  action: toggleDetailsLayer,
                }}
              />
            ) : null}

            {!detail && !detailError ? (<LoadDetails />) : null}

            {detail ? (
              <>
                {decipher?.client?.trl && (
                  <Box
                    background='status-warning'
                    pad={{ horizontal: 'large', vertical: 'xsmall' }}
                    flex={{ shrink: 0 }}
                  >
                    <Text size='small' color='white'>
                      Os dados abaixo são uma prévia, pois você está em período de avaliação do sistema.
                    </Text>
                  </Box>)
                }

                <Box
                  margin={{
                    horizontal: 'large',
                  }}
                  flex={{ shrink: 0 }}
                  pad={{ vertical: size === 'small' ? 'xlarge' : 'large' }}
                >
                  {/* Cabeçalho */}
                  <Box
                    direction='row'
                    justify='between'
                  >
                    <Box
                      direction='row'
                      gap='small'
                      align='baseline'
                    >
                      <Box>
                        <Text
                          size={ size === 'small' ? 'large' : 'xlarge' }
                          textAlign='start'
                        >
                          {detail.city.name}
                        </Text>
                      </Box>

                      <Box>
                        <Tag
                          text={detail.city.stateAbbrev}
                          color='status-ok'
                        />
                      </Box>
                    </Box>

                    <Button
                      plain
                      title='Fechar'
                      icon={<FormClose />}
                      onClick={toggleDetailsLayer}
                    />
                  </Box>


                  { /* Prefeito */}
                  <Box
                    margin={{ top: 'medium' }}
                    gap='xsmall'
                  >
                    <Text
                      size={ size === 'small' ? 'small' : 'medium' }
                      color='dark-2'
                      textAlign='start'
                    >
                      Prefeito
                    </Text>
                  </Box>

                  <Box
                    margin={{ top: 'medium' }}
                    round='xsmall'
                    border={{ color: 'light-6' }}
                  >
                    {/* Dados prefeito */}
                    <Box
                      pad={{
                        horizontal: size === 'xsmall' || size === 'small' ? 'medium':  'small',
                        vertical: size === 'xsmall' || size === 'small' ? 'medium' : 'medium',
                      }}
                    >
                      {mayors.length ? (
                        mayors.map((mayor, index) => {
                          return (
                            <Box
                              key={index}
                              gap='xsmall'
                            >
                              <Box
                                direction='row'
                                justify='between'
                                align='center'
                              >
                                <Box
                                  direction='row'
                                  gap='small'
                                  align='center'
                                >
                                  <Text size='large'>{mayor.name}</Text>
                                  <Text size='small' color='dark-1' weight='bolder'>{mayor.number}</Text>
                                </Box>

                                <Box>
                                  <Tag
                                    text={mayor.acronym}
                                    color={mayor.color}
                                  />
                                </Box>
                              </Box>

                              <Box
                                direction='row'
                                justify='between'
                                align='center'
                                gap='small'
                              >
                                <Tag
                                  text={mayor.is ? 'eleito' : 'não eleito'}
                                  color={mayor.is ? 'status-ok' : 'light-6'}
                                />

                                <Text size='xsmall' color='dark-1'>{formattedNumber(mayor.votes)} votos</Text>
                              </Box>
                            </Box>
                          )
                        })
                      ) : null}
                    </Box>


                    {/* Votação prefeito */}
                    {votesMayors ? (
                      <Box
                        direction='row'
                        pad={{
                          horizontal: size === 'xsmall' || size === 'small' ? 'medium':  'small',
                          vertical: size === 'xsmall' || size === 'small' ? 'medium' : 'medium',
                        }}
                        gap='small'
                      >
                        <Box
                          border={{ color: 'light-6' }}
                          background='light-1'
                          round='xsmall'
                          pad={{
                            vertical: size === 'xsmall' || size === 'small' ? 'medium' : 'small',
                            horizontal: size === 'xsmall' || size === 'small' ? 'medium' : 'small',
                          }}
                          fill
                        >
                          <Text size='xsmall' color='dark-2'>Total de votos</Text>
                          <Text size='medium' color='dark-1'>{formattedNumber(votesMayors.totalVotes)}</Text>
                        </Box>

                        <Box
                          border={{ color: 'light-6' }}
                          background='light-1'
                          round='xsmall'
                          pad={{
                            vertical: size === 'xsmall' || size === 'small' ? 'medium' : 'small',
                            horizontal: size === 'xsmall' || size === 'small' ? 'medium' : 'small',
                          }}
                          fill
                        >
                          <Text size='xsmall'>Válidos</Text>
                          <Text size='medium'>{formattedNumber(votesMayors.validVotes)}</Text>
                        </Box>

                        <Box
                          border={{ color: 'light-6' }}
                          background='light-1'
                          round='xsmall'
                          pad={{
                            vertical: size === 'xsmall' || size === 'small' ? 'medium' : 'small',
                            horizontal: size === 'xsmall' || size === 'small' ? 'medium' : 'small',
                          }}
                          fill
                        >
                          <Text size='xsmall'>Brancos</Text>
                          <Text size='medium'>{formattedNumber(votesMayors.blankVotes)}</Text>
                        </Box>

                        <Box
                          border={{ color: 'light-6' }}
                          background='light-1'
                          round='xsmall'
                          pad={{
                            vertical: size === 'xsmall' || size === 'small' ? 'medium' : 'small',
                            horizontal: size === 'xsmall' || size === 'small' ? 'medium' : 'small',
                          }}
                          fill
                        >
                          <Text size='xsmall'>Nulos</Text>
                          <Text size='medium'>{formattedNumber(votesMayors.nullVotes)}</Text>
                        </Box>

                        <Box
                          border={{ color: 'light-6' }}
                          background='light-1'
                          round='xsmall'
                          pad={{
                            vertical: size === 'xsmall' || size === 'small' ? 'medium' : 'small',
                            horizontal: size === 'xsmall' || size === 'small' ? 'medium' : 'small',
                          }}
                          fill
                        >
                          <Text size='xsmall'>Abstenções</Text>
                          <Text size='medium'>{formattedNumber(votesMayors.abstentions)}</Text>
                        </Box>
                      </Box>
                    ) : null}
                  </Box>


                  { /* Vereadores */}
                  <Box
                    margin={{ top: size === 'xsmall' || size === 'small' ? 'large' : 'medium' }}
                    gap='xsmall'
                  >
                    <Text
                      size={ size === 'small' ? 'small' : 'medium' }
                      color='dark-2'
                      textAlign='start'
                    >
                      Vereadores
                    </Text>
                  </Box>

                  <Box
                    margin={{ top: 'medium' }}
                    round='xsmall'
                    border={{ color: 'light-6' }}
                  >
                    {/* Dados vereadores */}
                    <Box
                      pad={{
                        horizontal: size === 'xsmall' || size === 'small' ? 'medium':  'small',
                        vertical: size === 'xsmall' || size === 'small' ? 'medium' : 'medium',
                      }}
                    >
                      {councilors.length ? (
                        councilors
                        .sort((a, b) => b.votes - a.votes).map((councilor, index) => {
                          return (
                            <Box
                              key={index}
                              gap='xxsmall'
                              border={{
                                side: 'bottom',
                                color: 'light-6',
                              }}
                              pad={{ bottom:  size === 'xsmall' || size === 'small' ? 'medium' : 'small' }}
                              margin={{ bottom: size === 'xsmall' || size === 'small' ? 'small' : 'xsmall' }}
                            >
                              <Box
                                direction='row'
                                justify='between'
                                align='center'
                              >
                                <Box
                                  direction='row'
                                  gap='small'
                                  align='center'
                                >
                                  <Text size='small'>{councilor.name}</Text>
                                  <Text size='small' color='dark-1' weight='bolder'>{councilor.number}</Text>
                                </Box>

                                <Box>
                                  <Tag
                                    text={councilor.acronym}
                                    color={councilor.color}
                                  />
                                </Box>
                              </Box>

                              <Box
                                direction='row'
                                justify='between'
                                align='center'
                                gap='small'
                              >
                                <Tag
                                  text={councilor.is ? 'eleito' : 'não eleito'}
                                  color={councilor.is ? 'status-ok' : 'light-6'}
                                />

                                <Text size='xsmall' color='dark-1'>{formattedNumber(councilor.votes)} votos</Text>
                              </Box>
                            </Box>
                          )
                        })
                      ) : null}
                    </Box>


                    {/* Votação Vereador */}
                    {votesCouncilors ? (
                      <Box
                        direction='row'
                        pad={{
                          horizontal: size === 'xsmall' || size === 'small' ? 'medium':  'small',
                          vertical: size === 'xsmall' || size === 'small' ? 'medium' : 'medium',
                        }}
                        gap='small'
                      >
                        <Box
                          border={{ color: 'light-6' }}
                          background='light-1'
                          round='xsmall'
                          pad={{
                            vertical: size === 'xsmall' || size === 'small' ? 'medium' : 'small',
                            horizontal: size === 'xsmall' || size === 'small' ? 'medium' : 'small',
                          }}
                          fill
                        >
                          <Text size='xsmall' color='dark-2'>Total de votos</Text>
                          <Text size='medium' color='dark-1'>{formattedNumber(votesCouncilors.totalVotes)}</Text>
                        </Box>

                        <Box
                          border={{ color: 'light-6' }}
                          background='light-1'
                          round='xsmall'
                          pad={{
                            vertical: size === 'xsmall' || size === 'small' ? 'medium' : 'small',
                            horizontal: size === 'xsmall' || size === 'small' ? 'medium' : 'small',
                          }}
                          fill
                        >
                          <Text size='xsmall'>Válidos</Text>
                          <Text size='medium'>{formattedNumber(votesCouncilors.validVotes)}</Text>
                        </Box>

                        <Box
                          border={{ color: 'light-6' }}
                          background='light-1'
                          round='xsmall'
                          pad={{
                            vertical: size === 'xsmall' || size === 'small' ? 'medium' : 'small',
                            horizontal: size === 'xsmall' || size === 'small' ? 'medium' : 'small',
                          }}
                          fill
                        >
                          <Text size='xsmall'>Brancos</Text>
                          <Text size='medium'>{formattedNumber(votesCouncilors.blankVotes)}</Text>
                        </Box>

                        <Box
                          border={{ color: 'light-6' }}
                          background='light-1'
                          round='xsmall'
                          pad={{
                            vertical: size === 'xsmall' || size === 'small' ? 'medium' : 'small',
                            horizontal: size === 'xsmall' || size === 'small' ? 'medium' : 'small',
                          }}
                          fill
                        >
                          <Text size='xsmall'>Nulos</Text>
                          <Text size='medium'>{formattedNumber(votesCouncilors.nullVotes)}</Text>
                        </Box>

                        <Box
                          border={{ color: 'light-6' }}
                          background='light-1'
                          round='xsmall'
                          pad={{
                            vertical: size === 'xsmall' || size === 'small' ? 'medium' : 'small',
                            horizontal: size === 'xsmall' || size === 'small' ? 'medium' : 'small',
                          }}
                          fill
                        >
                          <Text size='xsmall'>Abstenções</Text>
                          <Text size='medium'>{formattedNumber(votesCouncilors.abstentions)}</Text>
                        </Box>
                      </Box>
                    ) : null}
                  </Box>
                </Box>
            </>) : null}

          </Box>
        </Layer>
      )}
    </ResponsiveContext.Consumer>
  )
}

const mapStateToProps = ({ layerReducer, layerDetailsReducer }) => ({
  toggleLayer: layerReducer.toggleLayer,
  detail: layerDetailsReducer.detail,
  detailError: layerDetailsReducer.detailError,
});

const mapDispatchToProps = dispatch =>
  bindActionCreators({
    toggleDetailsLayer,
  }, dispatch)

export default connect(mapStateToProps, mapDispatchToProps)(Details);
